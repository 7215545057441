import { useState } from 'react'

import Loader from '../loader'

const host = process.env.REACT_APP_HOST_API_URL

const Modal = ({isOpen, onClose, data}) => {

    const [isDisabled, setIsDisabled] = useState(false)

    if (!isOpen) {
        return null;
    }

    const cancel = (status) => {
        setIsDisabled(true)
        const body = {
            doctor: data.doctorUid,
            timestamp: data.date.unix(),
            status: status,
        }        
        fetch(`${host}/api/slots/status/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json',
            }
        })
        // .then((res) => {setTimeout(window.reload, 1000); onClose()})
        // .then((res) => {setTimeout(location.reload, 1000); onClose()})
        .then((res) => window.location.reload())
    }

    return <>
        <div className="fixed inset-0 z-50 overflow-auto bg-opacity-50 bg-black">
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white rounded-lg p-6 m-4 max-w-xl">
                    {/* <div className="h-8">
                        <button onClick={onClose} className="float-right">X</button>
                    </div> */}
                    <div className="text-center text-xl">
                        На это время ({data.timestamp}) 
                        у Вас назначен прием с {data.firstName} {data.lastName} ({data.phone}).
                    </div>
                    <button 
                        disabled={isDisabled}
                        onClick={() => cancel('FREE')}
                        className="bg-green-100 mt-5 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        {isDisabled ? <Loader /> : 'Отменить прием'}
                    </button>
                    <button 
                    disabled={isDisabled}
                        onClick={() => cancel('OFF')}
                        className="bg-neutral-200 mt-5 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        {isDisabled ? <Loader /> : 'Отменить прием и закрыть окно для записи'}
                    </button>                    
                    <div className="text-center mt-3">
                        В случае отмены приема мы пришлем пациенту уведомление об этом
                    </div>

                    <button 
                        disabled={isDisabled}
                        onClick={onClose}
                        className="bg-blue-100 mt-10 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        Оставить запись
                    </button>                      
                </div>
            </div>
        </div>
    </>

}

export default Modal
