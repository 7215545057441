import * as Sentry from "@sentry/react";

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Schedule from './components/schedule';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Schedule />
  </React.StrictMode>
);
