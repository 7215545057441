import { useState, useEffect } from 'react'
import Modal from './modal'
import Help from './help'

import Loader from '../loader'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.locale('ru')

const freeSlots = []
const modalData = {}

const daysToShow = 5
// const doctorUid = 'cef9e96b-688d-45c7-9d04-5cbdf8c0337a'
const duration = 60

const host = process.env.REACT_APP_HOST_API_URL

// const slotsClassMap = {
//     FREE: "bg-neutral-200",
//     OFF: "bg-neutral-100",
//     BUSY: "bg-blue-100",
// }
const slotsClassMap = {
    FREE: "bg-green-100",
    OFF: "bg-neutral-200",
    BUSY: "bg-blue-100",
}


const Schedule = () => {

    const [doctorUid, setDoctorUid] = useState(null)

    useEffect(() => {
      const url = new URL(window.location.href)
      setDoctorUid(url.pathname.split('/').pop())
    }, [])

    const generateDateRange = (startDate) => {
        const start = dayjs(startDate).startOf('day').add(6, 'hour')
        const end = dayjs(startDate).endOf('day').subtract(30, 'minute')
        const step = duration
        const result = []
      
        let current = start
      
        while (current.isBefore(end) || current.isSame(end)) {
            result.push({
                date: current,
                status: freeSlots[current.unix()] ? freeSlots[current.unix()] : 'OFF',
            })
            current = current.add(step, 'minute')
        }
      
        return result
      }


    const [minDate, setMinDate] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState(dayjs())

    const [dateRange, setDateRange] = useState(generateDateRange(dayjs()))
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHelpOpen, setIsHelpOpen] = useState(false)

    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [loadingState, setLoadingState] = useState(false)

    const monthsToShow = (date) => {
        const currDate = dayjs(date)
        const nextDate = currDate.add(daysToShow-1, 'day')

        if (currDate.month() !== nextDate.month()) {
            return `${currDate.format('MMMM')} - ${nextDate.format('MMMM')}`
        } else {
            return currDate.format('MMMM')
        }

    }

    const setLoadState = (id, state) => {
        setLoadingState(prevState => ({ ...prevState, [id]: state }))
    }

    useEffect(() => {
        if (doctorUid != null || doctorUid != '') {
            fetch(`${host}/api/slots/${doctorUid}/${selectedDate.unix()}/`)
                .then((res) => res.json())
                .then((data) => {
                    setIsFirstLoad(false)
                    Object.assign(freeSlots, data)
                    setDateRange(generateDateRange(selectedDate))
                })
        }
    }, [doctorUid, selectedDate])

    useEffect(() => setSelectedDate(minDate), [minDate])

    const setSlotStatus = (slot, status) => {
        const timestamp = slot.unix()
        const body = {
            doctor: doctorUid,
            timestamp: timestamp,
            status: status,
        }
        return fetch(`${host}/api/slots/status/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json',
            }
        })
        .then((res) => res.json())
        .then((data) => {
            freeSlots[timestamp] = status
            setDateRange(generateDateRange(selectedDate))
        }) 
    }    

    if (doctorUid == null || doctorUid == '') {
        return <div>Загрузка...</div>
    }

    return <>
        <Modal 
            data={modalData} 
            isOpen={isModalOpen} 
            onClose={() => setIsModalOpen(false)}
        />
        <Help 
            data={null} 
            isOpen={isHelpOpen} 
            onClose={() => setIsHelpOpen(false)}
        />
        
        <div className="flex items-center justify-center bg-gray-100 _h-full">
            <div className="bg-white p-3 m-3 rounded shadow flex flex-grow">
                <div className="flex-grow">
                    <div className="flex justify-between items-center px-2 mb-5">
                        <div className="mb-2 mt-0 text-xl font-medium leading-tight flex ">
                            {monthsToShow(minDate)}
                            {/* <span onClick={() => setIsHelpOpen(true)}>&nbsp;[ ? ]</span> */}
                            <div className="w-8 ml-2" onClick={() => setIsHelpOpen(true)}>
                                <svg style={{marginTop: '-5px'}} viewBox="0 0 24 24" data-testid="HelpOutlineIcon"><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path></svg>
                            </div>
                        </div>
                        <div>
                            <button 
                                className="rounded bg-neutral-100 px-3 pb-2 pt-2.5 text-xl ml-2" 
                                onClick={() => setMinDate(minDate.subtract(daysToShow, 'day'))}
                            >
                                &#x2190;
                            </button>
                            <button 
                                className="rounded bg-neutral-100 px-3 pb-2 pt-2.5 text-xl ml-2" 
                                onClick={() => setMinDate(minDate.add(daysToShow, 'day'))}
                            >
                                &#8594;
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-5">
                        {Array.from({length: daysToShow}, (_, index) => minDate.add(index, 'day')).map((date) => <>
                            <div 
                                key={date} 
                                className={
                                    `${selectedDate.day() == date.day() ? 'bg-neutral-300' : 'bg-neutral-100'} hover:bg-gray-300 cursor-pointer rounded px-4 py-2 mx-2 text-center`
                                }
                                onClick={() => setSelectedDate(date)}
                            >
                                <div>
                                    {date.format('dd')}
                                </div>
                                <div className="font-medium">
                                    {date.format('DD')}
                                </div>
                            </div>
                        </>)}
                    </div>
                    {isFirstLoad && <div className="mt-5 text-center">Загрузка...</div>}
                    <div className='px-1 py-5 grid grid-cols-3 _h-screen grid-height'>
                        {!isFirstLoad && dateRange.map(i => <>
                            <div key={i.date} className="mx-1 align-center">
                                <button 
                                    disabled={loadingState[i.date] || false}
                                    className={`${slotsClassMap[i.status]} h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800`}
                                    onClick={() => {
                                            setLoadState(i.date, true)
                                            if (i.status == 'BUSY') {
                                                fetch(`${host}/api/patients/${doctorUid}/${i.date.unix()}/`)
                                                    .then((res) => res.json())
                                                    .then((data) => { 
                                                        modalData.doctorUid = doctorUid
                                                        modalData.firstName = data.first_name
                                                        modalData.lastName = data.last_name
                                                        modalData.phone = data.phone
                                                        modalData.timestamp = i.date.format('DD.MM.YYYY HH:mm')
                                                        modalData.date = i.date
                                                        setIsModalOpen(true)
                                                        setLoadState(i.date, false)
                                                    })
                                            } else {
                                                setSlotStatus(i.date, i.status == 'OFF' ? 'FREE' : 'OFF').then(_ => setLoadState(i.date, false))
                                            }
                                        }
                                    }
                                >
                                    {loadingState[i.date] || false ? <Loader /> : i.date.format('HH:mm')}
                                </button>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </div>
    </>

}



export default Schedule