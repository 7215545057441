import { useState } from 'react'

const Help = ({isOpen, onClose}) => {

    const [isDisabled, setIsDisabled] = useState(false)

    if (!isOpen) {
        return null;
    }

    return <>
        <div className="fixed inset-0 z-50 overflow-auto bg-opacity-50 bg-black">
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white rounded-lg p-6 m-4 max-w-xl modal-top">
                    {/* <div className="h-8">
                        <button onClick={onClose} className="float-right">X</button>
                    </div> */}
                    <div className="text-center text-xl">
                        Как пользоваться сервисом?
                    </div>
                    <div className="text-center mt-3">
                        {/* С помощью сервиса мы можете выбирать доступные для приема временные слоты, а также видеть уже записашихся пациентов и управлять их записью. */}
                        Сервис позволяет указать время, в которое вы готовы принять пациента. 
                        <br />
                        Также есть возможность видеть уже записашихся пациентов и управлять их записью.
                    </div>
                    <div className="text-center mt-3">
                        {/* Сверху можно выбрать интересующие вас дни, по клику на конркетную дату вы увидите возможные часы для прием. По-умолчанию они все неактивны. */}
                        Выберите сверху нужную недели и конкретный день и выберите часы, в которые сможете вести прием.
                        <br />
                        Слоты отмечаются разными цветами, например если цвет такой, то:
                    </div>

                    <button 
                        className="bg-green-100 mt-5 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800">
                        Вы готовы принять пациента в этом время
                    </button>
                    <button 
                        className="bg-neutral-200 mt-5 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        В это время запись невозможна
                    </button>                    
                    <button 
                        className="bg-blue-100 mt-5 h-16 w-full rounded px-6 pb-2 pt-2.5 text-l text-neutral-800"
                    >
                        На это время уже есть пациент, детали будут доступны по клику
                    </button>          
                    <div className="mt-10 underline text-center" onClick={onClose}>
                        Понятно, спасибо!
                    </div>            
                </div>
            </div>
        </div>
    </>

}

export default Help
